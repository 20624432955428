<template>
  <div>
   
    <el-card class="intanle" v-if="!istanchuang">
      <span class="h1">商店管理列表</span>
    </el-card>
    <el-card class="ft-14">
      <el-row>
        <el-col>
          <!-- <span>商店名：</span>
          <el-input v-model="merchantName" style="width: 19%" placeholder="请输入商店名"></el-input>
          <span style="float: right; z-index: 5; position: relative">
            <el-button style="margin-left: 20px;border: none" type="primary" @click="searchList">検索</el-button>
            <el-button type="info" style="border: none" @click="resetList">リセット</el-button>
          </span> -->
          <p v-if="!istanchuang">
            <span style="float: left">
              <el-button @click="toinsert" type="primary" icon="el-icon-plus" style="background-color: #2a4b9b; border: none">新規</el-button>
            </span>
          </p>
        </el-col>
      </el-row>
    </el-card>
    <!-- リストカードエリア -->
    <el-card>
      <el-table
        max-height="550px"
        @sort-change="sortChange"
        @current-change="selectThisLine"
        :highlight-current-row="istanchuang"
        ref="multipleTable"
        v-loading="loading"
        :data="merchantlist"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <el-table-column label="商店名" prop="merchantName" v-if="!istanchuang">
          <template slot-scope="scope">
            <el-button @click="openDetails(scope.row)" type="text" size="small" style="color: #409EFF; text-decoration: underline">{{ scope.row.merchantName }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="商店名" prop="merchantName" v-else></el-table-column>
        <el-table-column label="商店番号" prop="merchantNo"></el-table-column>
        <el-table-column label="利用可能かどうか">
          <template slot-scope="scope">
            <span v-if="scope.row.enabled == 1">はい</span>
            <span v-if="scope.row.enabled == 0">いいえ</span>
          </template>
        </el-table-column>
        <el-table-column label="作成日時" prop="createTime" sortable="custom"></el-table-column>
        <el-table-column label="操作" v-if="!istanchuang">
          <template slot-scope="scope">
            <el-button @click.native.stop="handleupdate(scope.$index, scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">編集</el-button>
            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="small" class="el-icon-delete" style="color: #da0909">削除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- ページング -->
      <el-pagination v-if="total > 10" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
      <div style="clear:both;"></div>
    </el-card>
  </div>
</template>

<script>
import merchantService from '@/api/merchant/merchantService'
export default {
  props: {
    istanchuang: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      merchantService: new merchantService(), //インターフェース定義
      merchantName: '', //商店名
      currentPage: 1, //ページ数
      pageSize: 10, //1ページあたりの件数
      total: 0, //合計数
      merchantlist: [], //商户列表数据
      loading: true, //ローディング
      merchantId: '', //商店ID
      sortBy: '',  //ソート
      multipleSelection: [] //複数選択されたデータ
    }
  },
  created() {
    this.merchantId = window.localStorage.getItem('merchantId')
    this.role = window.localStorage.getItem('currentRole')
    this.GetMerchantsList()
  },
  methods: {
    // チェックボックスの値を取得
    handleSelectionChange(val) {
      if (val.length != 0) {
        this.multipleSelection = val
        this.merchantId = val[0].merchantId
      }
    },
    // 父级的弹出层选中的数据
    selectThisLine(row) {
      this.item = row
      // this.$parent.submitForm();
      this.$emit('submitForm')
      // return item
    },
    // 传给父级
    passFather() {
      return this.item //this.ruleForm是子组件填写的数据
    },
    /* ページサイズ変更イベントのリスナー */
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.GetMerchantsList()
    },
    /* ページ番号変更イベントのリスナー */
    handleCurrentChange(newPage) {
      this.currentPage = newPage
      this.GetMerchantsList()
    },
    //リスト取得
    async GetMerchantsList() {
      const data = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        merchantName: this.merchantName,
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      this.merchantService.list(data).then(res => {
        if (res.data.success == 'true') {
          this.merchantlist = res.data.result
          this.loading = false
          this.total = Number(res.data.maxRecords)
          for (var i = 0; i < this.merchantlist.length; i++) {
            if (this.merchantlist[i].enabled == 1) {
              this.merchantlist[i].enabled = true
            } else {
              this.merchantlist[i].enabled = false
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    //照会
    async searchList() {
      this.currentPage = 1
      this.GetMerchantsList()
    },
    //詳細ページ遷移
    openDetails(row) {
      this.local()
      this.$router.push({
        name: 'GetMerchantsDetails',
        query: {
          merchantId: row.merchantId,
          type: 'details'
        }
      })
    },
    //変更ページ移動
    handleupdate(index, row) {
      this.local()
      this.$router.push({
        name: 'GetMerchantsDetails',
        query: {
          merchantId: row.merchantId,
          type: 'update',
          currentPage: this.currentPage
        }
      })
    },
    //新規追加ページ遷移
    toinsert() {
      this.local()
      this.$router.push({
        name: 'GetMerchantsDetails',
        query: {
          type: 'insert'
        }
      })
    },
    //リセット
    resetList() {
      this.currentPage = 1
      this.merchantName = ''
      this.GetMerchantsList()
    },
    //设置利用可能かどうか
    async SetMerchantEnableOrDisable(row) {
      let enabled = '0'
      if (row.enabled) {
        enabled = '1'
      } else {
        enabled = '0'
      }
      let data = {
        merchantId: row.merchantId,
        enabled: enabled
      }
      const { data: res } = await this.$http.post(this.$url.SetMerchantEnableOrDisable(data.merchantId, data.enabled), data)
      if (res.msgCode == 1) {
        this.GetMerchantsList()
        this.loading = false
      } else {
        this.$message.error(res.message)
      }
    },
    //1行削除
    handleDelete(row) {
      this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const date = {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            merchantId: row.merchantId
          }
          this.merchantService.delete(date).then(res => {
            if (res.data.success == 'true') {
              this.$message({
                type: 'success',
                message: '削除成功!'
              })
              this.GetMerchantsList()
            } else {
              this.$message.error(res.data.message)
            }
          })
        })
        .catch(err => {
          this.GetMerchantsList()
        })
    },
    // 複数選択削除
    async handleDelete1() {
      var data1 = this.multipleSelection
      var data2 = []
      if (this.multipleSelection == undefined) {
        this.$confirm('データを選択してください', '表示', {
          type: 'warning'
        })
      } else {
        this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            for (var i = 0; i < data1.length; i++) {
              data2.push(data1[i].merchantId)
            }
            // const { data: res } = this.$http.delete(
            //     this.$url.DeletePet,
            //     {
            //         data: data2,
            //         headers: {
            //             "Content-Type":
            //                 "application/json;charset=UTF-8"
            //         }
            //     }
            // )
            this.$message({
              type: 'success',
              message: '削除成功!'
            })
            this.GetMerchantsList()
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err
            })
          })
      }
    },
    //リストのソート
    sortChange(column) {
      if (column.order == 'ascending') {
        if (column.prop == 'createTime') {
          this.sortBy = 'm.createTime ASC'
        }
      } else if (column.order == 'descending') {
        if (column.prop == 'createTime') {
          this.sortBy = 'm.createTime DESC'
        }
      }
      this.GetMerchantsList()
    },
    //编辑的时候把筛选条件放入缓存
    local() {
      var zph_management_params = {
        merchantName: this.merchantName, //商店名
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      localStorage.setItem('zph_management_params', JSON.stringify(zph_management_params))
    }
  }
}
</script>

<style lang="less" scoped>
.cl-hui {
  color: #b2b2b2 !important;
}

.cl-bl {
  color: #606262;
}

/deep/.el-table td.el-table__cell div {
  /* box-sizing: border-box; */
  margin-top: -2px !important;
}
</style>
