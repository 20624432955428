<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ title }}</span>
      <span @click="goBack()">
        <el-button class="el-button1" type="info" style="float: right">戻る</el-button>
      </span>
    </el-card>
    <el-card>
      <!-- 情報入力 -->
      <el-form ref="form" label-position="right" label-width="20%" :rules="rules" :model="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名1:" prop="productName1">
              <el-input placeholder="商品名1を入力してください" v-model="form.productName1" :disabled="disabled" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品名2:" prop="productName2">
              <el-input placeholder="商品名2を入力してください" :disabled="disabled" v-model="form.productName2" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="最低価格:" prop="lowestPrice">
              <el-input placeholder="最低価格を入力してください" :disabled="disabled" v-model="form.lowestPrice" @keyup.native="form.lowestPrice = fudianshu(form.lowestPrice)" style=" width: 45%"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="元の価格:" prop="originalPrice">
              <el-input placeholder="元の価格を入力してください" v-model="form.originalPrice" @keyup.native="form.originalPrice = fudianshu(form.originalPrice)" :disabled="disabled" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="商品タイトル:" prop="productTitle">
              <el-input placeholder="商品タイトルを入力してください" v-model="form.productTitle" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="推薦タイプ:" prop="recommandType">
              <el-select :disabled="disabled" v-model="form.recommandType" placeholder="推薦タイプを選択してください" style="width: 45%">
                <el-option v-for="item in recommandTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商店:" prop="merchantName">
              <el-input readonly placeholder="商店を選択してください" v-model="form.merchantName" :disabled="disabled" style="width: 45%" @focus="merchantDialog = true"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品カテゴリ:" prop="categoryId">
              <el-select :disabled="disabled" v-model="form.categoryId" placeholder="商品カテゴリを選択してください" style="width: 45%">
                <el-option v-for="item in categorylist" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="在庫:" prop="stockAmount">
              <el-input placeholder="在庫数量を入力してください" v-model="form.stockAmount" onkeyup="value=value.replace(/[^\d]/g,'')" style="width: 45%"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="販売数量:" prop="soldAmount">
              <el-input placeholder="販売数量を入力してください" v-model="form.soldAmount" onkeyup="value=value.replace(/[^\d]/g,'')" style="width: 45%"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="重み:" prop="weight">
              <el-input placeholder="重みを入力してください" v-model="form.weight" onkeyup="value=value.replace(/[^\d]/g,'')" :disabled="disabled" style="width: 45%"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="クリック数:" prop="hits">
              <el-input placeholder="クリック数を入力してください" v-model="form.hits" onkeyup="value=value.replace(/[^\d]/g,'')" :disabled="disabled" style="width: 45%"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="SALE商品:" prop="isSale">
              <el-select :disabled="disabled" v-model="form.isSale" placeholder="SALE商品かどうかを選択してくださ" style="width: 45%">
                <el-option v-for="item in isSaleList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" v-if="flagss || type == 'insert'">
            <el-form-item label="商品説明:" prop="productDesc" label-width="10%">
              <fuwenbenVue ref="eidtList" :flags="type == 'details'" :fuwenbenneirong="fuwenbenneirong"> </fuwenbenVue>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="ヘッダー画像:" label-width="10%" class="is-required">
              <span class="el-icon-warning-outline" style="color: red"><span style="margin-left: 10px">画像サイズの比率は480px*480pxです</span> </span>
              <el-upload
                :limit="1"
                :show-file-list="true"
                :file-list="pictureTT"
                action="#"
                :http-request="uploadImg"
                accept=".jpg,.png,.jpeg,.tif,.bmp,.gif"
                list-type="picture-card"
                :on-success="successTT"
                :headers="MyHeader"
                :on-preview="handlePictureCardPreviewTT"
                :on-error="errorTT"
                :on-exceed="handleExceedTT"
                :before-remove="handleBeforeRemoveTT"
                :disabled="disabled"
                :class="{ hide_box: upload_btn }"
                style="width: 75.5%"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisibleTT" style="text-align:center;">
                <img height="400px" :src="dialogImageUrlTT" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="商品スライド画像:" label-width="10%" class="is-required">
              <span class="el-icon-warning-outline" style="color: red"><span style="margin-left: 10px">画像サイズの比率は480px*480pxです</span> </span>
              <tupian :pictureIds="pictureIds" ref="eidtLists" :pictureLB="pictureLB"></tupian>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="logtime" v-if="this.type != 'insert'">
          <el-col :span="12">
            <el-form-item label="作成日時:" label-width="20%">
              {{ createTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新日時:" label-width="20%">
              {{ updateTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作成者:" label-width="20%">
              {{ createPersonName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新者:" label-width="20%">
              {{ updatePersonName }}
            </el-form-item>
          </el-col>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="" v-if="this.type != 'details'">
              <el-button type="primary" @click="onSubmit('form')">{{ content }}</el-button>
              <el-button @click="goBack">取消</el-button>
              <!-- <el-button @click="vidufwiuef">取消1</el-button> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 関連する商人のポップアップレイヤー -->
      <el-dialog :visible.sync="merchantDialog" direction="rtl" size="50%">
        <MERCHARTS :istanchuang="istanchuang" ref="eidtListM" @submitForm="submitForm"></MERCHARTS>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import MERCHARTS from '../Merchants/GetMerchantsList.vue'
import fuwenbenVue from './fuwenben.vue'
import tupian from './tupian.vue'
import productService from '@/api/product/productService'
import categoryService from '@/api/category/categoryService'
import axios from 'axios'

export default {
  inject: ['reload'],
  name: 'GetProductsDetails',
  components: {
    MERCHARTS,
    fuwenbenVue,
    tupian
  },
  data() {
    return {
      productService: new productService(), //インターフェース定義 商品
      categoryService: new categoryService(), //インターフェース定義 カテゴリ
      flagss: false,
      fuwenbenneirong: '',
      isxianshianniu: false,
      upload_btn: false,
      upload_btn1: false,
      action: '',
      type: this.$route.query.type,
      productId: this.$route.query.productId,
      merchantDialog: false,
      loading: false,
      istanchuang: true, //親子通信パラメータ
      //
      firstPicId: [],
      pictureIds: [],
      //
      // スライド
      hideUpload: false,
      form: {
        productName1: '', // 商品名1
        productName2: '', // 商品名2
        lowestPrice: '', // 最低価格
        originalPrice: '', // 元の価格
        productTitle: '', // 商品タイトル
        recommandType: '', // 推奨タイプ
        /** 商店 */
        merchantName: '', //商店名
        merchantId: '', //商店ID

        categoryId: '', // 商品カテゴリ
        stockAmount: '', // 在庫数
        soldAmount: '', // 販売数
        weight: '', // 重み
        hits: '', // クリック数
        isSale: '', // プロモーション中かどうか
        productDesc: '', // 商品説明
        firstPicId: [], // 商品名1
        pictureIds: [], // 商品名1

        isDefault: true,
        fileId: ''
      },

      title: '',
      content: '',
      UploadPics: '',
      UploadPicture: '',
      MyHeader: {
        accessToken: '',
        fp: ''
      },
      disabled: false,
      disabledQT: false,
      merchant: '関連する商店を選択してください',
      createTime: '',
      updateTime: '',
      createPersonName: '',
      updatePersonName: '',
      headerName: '',
      categorylist: [],
      // 推荐类型下拉选项
      recommandTypeList: [
        { label: '人気商品', value: '1' },
        { label: 'SALE', value: '2' },
        { label: '新着', value: '3' }
      ],
      isSaleList: [
        { label: 'はい', value: '1' },
        { label: 'いいえ', value: '0' }
      ],
      categorylist: [], // 商品カテゴリ
      // ヘッダー画像
      pictureTT: [],
      dialogImageUrlTT: '',
      // スライド画像
      pictureLB: [],
      dialogImageUrlLB: '',
      dialogVisibleTT: false,
      dialogImageUrl: '',
      rules: {
        productName1: [
          {
            required: true,
            message: '商品名1を入力してください',
            trigger: 'blur'
          }
        ],
        lowestPrice: [
          {
            required: true,
            message: '最低価格を入力してください',
            trigger: 'blur'
          }
        ],
        originalPrice: [
          {
            required: true,
            message: '元の価格を入力してください',
            trigger: 'blur'
          }
        ],
        productTitle: [
          {
            required: true,
            message: '商品タイトルを入力してください',
            trigger: 'blur'
          }
        ],
        recommandType: [
          {
            required: true,
            message: '推奨タイプを選択してください',
            trigger: 'change'
          }
        ],
        categoryId: [
          {
            required: true,
            message: '商品カテゴリを選択してください',
            trigger: 'change'
          }
        ],
        merchantName: [
          {
            required: true,
            message: '商人を選択してください',
            trigger: 'change'
          }
        ],
        stockAmount: [
          {
            required: true,
            message: '在庫数量を入力してください',
            trigger: 'blur'
          }
        ],
        soldAmount: [
          {
            required: true,
            message: '販売数量を入力してください',
            trigger: 'blur'
          }
        ],
        weight: [
          {
            required: true,
            message: '重みを入力してください',
            trigger: 'blur'
          }
        ],
        hits: [
          {
            required: true,
            message: 'クリック数を入力してください',
            trigger: 'blur'
          }
        ],
        isSale: [
          {
            required: true,
            message: 'SALE商品かを選択してください',
            trigger: 'change'
          }
        ],
        productDesc: [
          {
            required: true,
            message: '商品説明を入力してください',
            trigger: 'blur'
          }
        ]
      }
    }
  },

  created() {
    this.UploadPics = this.$url.UploadPicsTt
    this.UploadPicture = this.$url.UploadPicture
    this.MyHeader.accessToken = localStorage.getItem('accessToken')
    this.MyHeader.fp = localStorage.getItem('fp')
    this.MyHeader.currentRole = localStorage.getItem('role')
    this.role = window.localStorage.getItem('role')
    if (this.type == 'details') {
      this.title = '商品管理詳細'
      this.headerName = '商品管理詳細'
      this.disabled = true
      this.upload_btn = true
      this.upload_btn1 = true
      this.disabledQT = true
      this.GetProductList()
    } else if (this.type == 'update') {
      this.title = '商品情報の修正'
      this.headerName = '商品情報の修正'
      this.content = '保存'
      if (this.role != 1) {
        this.disabledQT = true
      }
      this.GetProductList()
    } else if (this.type == 'insert') {
      this.title = '商品管理新規追加'
      this.headerName = '商品管理新規追加'
      this.content = '追加'
      if (this.role != 1) {
        this.form.merchantId = window.localStorage.getItem('merchantId')
      }
    }
    this.GetMerchantCategoryList()
  },

  methods: {
    // shurufusianshu
    fudianshu(val) {
      let str = val.toString()
      var len1 = str.substr(0, 1)
      var len2 = str.substr(0, 1)
      if (str.length > 1 && len1 == 0 && len2 != '.') {
        // str = str.substr(1, 1)
      }
      if (len1 == '.') {
        str = ''
      }
      if (str.indexOf('.') != -1) {
        var str1 = str.substr(str.indexOf('.') + 1)
        if (str1.indexOf('.') != -1) {
          str = str.substr(0, str.indexOf('.') + str1.indexOf('.') + 1)
        }
        if (str1.length > 2) {
          var str2 = str.substr(0, str.indexOf('.') + 1)
          var str3 = str.substr(str.indexOf('.') + 1, 2)
          return (str = str2 + str3)
        }
      }
      str = str.replace(/[^\d^\.]+/g, '')
      return str
    },
    async formDataToBase64(formData) {
      let base64Data = {};
      
      for (let [key, value] of formData.entries()) {
          if (value instanceof File) {
              const fileReader = new FileReader();
              const promise = new Promise((resolve, reject) => {
                  fileReader.onload = function(e) {
                      resolve(e.target.result.split(',')[1]); // 移除"data:image/*;"部分，仅保留Base64数据
                  };
                  fileReader.onerror = reject;
              });
              
              fileReader.readAsDataURL(value);
              base64Data[key] = await promise;
          }
      }
      
      
      return base64Data;
    },
    /**ヘッダー画像 */
    
    uploadImg(item) {
      this.loading = true
      //上传文件的需要formdata类型;所以要转

      // 判断文件是否大于10M
      if (item.file.size > 10 * 1024 * 1024) {
        this.$message.error('アップロードするファイルのサイズは10MBを超えてはいけません！')
        this.pictureTT = []
        return false
      }

      let originalFileExt = item.file.name.split('.')[1]

      // 如果originalFileExt在[jpg,png,jpeg,tif,bmp,gif]存在
      if (['jpg', 'png', 'jpeg', 'tif', 'bmp', 'gif'].indexOf(originalFileExt) == -1) {
        this.$message.error('アップロードファイルの拡張子が正しくない!')
        this.pictureTT = []
        return false
      }
      

      let FormDatas = new FormData()
      FormDatas.append('file',item.file);
      let base64Img = '';

      this.formDataToBase64(FormDatas).then(base64Data => {
        base64Img = base64Data;

        let accessToken = localStorage.getItem('authToken');
        let currentRole = localStorage.getItem('currentRole');
        
        let params = {
          accessToken: accessToken,
          currentRole,
          file: base64Img.file,
          originalFileName: item.file.name,
          originalFileExt
        }
        // FormDatas.append('user_token', this.user_token);
        // console.log(this.UploadPics)
        axios({
          url: this.UploadPicture,
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          timeout: 30000,
          data: params
        }).then( res => {
          this.form.firstPicId = res.data.fileId;
          // console.log(this.form.firstPicId)
        })
        // this.$http({
        //   method: 'post',
        //   url: this.UploadPics,
        //   headers: { 'Content-Type': 'multipart/form-data' },
        //   timeout: 30000,
        //   data: FormDatas
        //   }).then(res=>{

        //   })
      }).catch(error => {
        console.error('Error converting to Base64:', error);
      });
    },
    //トップ画像のアップロードが完了しました
    successTT(response) {
      if (response.msgCode == 1) {
        this.firstPicId = response.id
        this.$message.success('画像のアップロードに成功しました')
        this.upload_btn = true
      } else if (response.msgCode !== '1') {
        this.$message({
          type: 'error',
          message: response.message
        })
      }
    },

    //ヘッダー画像のアップロード失敗時
    errorTT(fileList) {
      this.$message.error('アップロード失敗')
      if (fileList.length >= 1) {
        this.isMax = true
      } else {
        this.isMax = false
      }
    },

    // ヘッダー画像のプレビュー
    handlePictureCardPreviewTT(file) {
      this.dialogImageUrl = file.url
      this.dialogImageUrlTT = file.url
      this.dialogVisibleTT = true
    },
    //ヘッダー画像の削除
    handleBeforeRemoveTT(file, fileList) {
      var mm = ''
      if (this.type == 'update' && file.response == undefined) {
        mm = file.url.slice(42)
        return this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.firstPicId = ''
          this.pictureTT = []
          this.upload_btn = false
        })
      } else {
        mm = file.response.id
        this.upload_btn = false
        let ids = new Set(this.firstPicId)
        ids.delete(mm)
        this.firstPicId = Array.from(ids)
      }
    },
    /**スライド */
    //スライド画像のアップロード成功
    successLB(response) {
      if (response.msgCode == '1') {
        this.pictureIds.push(response.id)
        this.$message.success('画像のアップロードに成功しました')
      } else if (response.msgCode !== '1') {
        this.$message({
          type: 'error',
          message: response.message
        })
      }
    },

    //スライド片アップロード失敗
    errorLB(err, file, fileList) {
      this.$message.error('アップロード失敗')
      if (fileList.length >= 1) {
        this.isMax = true
      } else {
        this.isMax = false
      }
    },

    //スライド画像のプレビュー
    handlePictureCardPreviewLB(file) {
      this.picture2s = file.url
      this.dialogVisible2 = true
    },
    //スライド画像の削除
    handleBeforeRemoveLB(file, fileList) {
      var mm = ''
      if (this.type == 'update' && file.response == undefined) {
        mm = file.url.slice(42)
        return this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          for (let i = 0; i < this.pictureIds.length; i++) {
            if (this.pictureIds[i] == file.url.substr(this.$url.urlcq.length)) {
              this.pictureIds.splice(i, 1)
            }
          }
        })
      } else {
        mm = file.response.id
        let ids = new Set(this.firstPicId)
        ids.delete(mm)
        this.firstPicId = Array.from(ids)
      }
    },
    //商品管理の詳細を取得
    async GetProductList() {
      const data = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        productId: this.productId //商品id
      }
      this.productService.detail(data).then(res => {
        if (res.data.success == 'true' && res.data.result) {
          // this.pictureTT = []
          // let result = res.data.result
          this.form = res.data.result
          this.fuwenbenneirong = res.data.result.productDesc
          this.createTime = res.data.result.createTime
          this.updateTime = res.data.result.updateTime
          this.createPersonName = res.data.result.createPersonName
          this.updatePersonName = res.data.result.updatePersonName
          this.flagss = true
          // ヘッダー画像
          if (this.form.firstPicId != '' && this.form.firstPicId != null) {
            var picIds = res.data.result.firstPicId.split(',')
            for (var i = 0; i < picIds.length; i++) {
              var uuu = {
                url: this.$url.urlcqs + picIds[i]
              }
              this.pictureTT.push(uuu)
            }
          }
          if (this.pictureTT.length != 0) {
            this.upload_btn = true
          }
          // this.firstPicId = [res.data.result.firstPicId]
          // スライド
          if (this.form.pictureIds != '' && this.form.pictureIds != null) {
            var picIds = res.data.result.pictureIds.split(',')
            for (var i = 0; i < picIds.length; i++) {
              if (picIds[i]) {
                this.pictureIds.push(picIds[i])
                var uuu = {
                  url: picIds[i]
                }
                this.pictureLB.push(uuu)
              }
            }
          }
          this.pictureIds = res.data.result.pictureIds
        } else {
          this.$message.error(res.data.message)
          this.flagss = true
        }
      })
    },

    //商品管理新規
    onSubmit(formName) {
      var eirong = this.$refs['eidtLists'].passFather()
      var zhongjianshang = []
      for (var i = 0; i < eirong.length; i++) {
        zhongjianshang.push(eirong[i].url.substr(this.$url.urlcq.length))
      }
      if (this.isxianshianniu) {
        zhongjianshang.unshift(this.pictureIds[0])
      }
      this.pictureIds = zhongjianshang
      this.fuwenbenneirong = this.$refs['eidtList'].passFather()
      this.form.productDesc = this.fuwenbenneirong
      let num = 0
      // if (this.firstPicId == '') {
      //     this.$message({
      //         message: '请上传头图',
      //         type: 'error'
      //     });
      //     return
      // }
      // if (this.pictureIds == '') {
      //     this.$message({
      //         message: '请上传スライド',
      //         type: 'error'
      //     });
      //     return
      // }

      if (this.type == 'insert') {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            const data = {
              accessToken: localStorage.getItem('authToken'),
              currentRole: localStorage.getItem('currentRole'),
              productName1: this.form.productName1, //商品名1
              productName2: this.form.productName2, //商品名2
              lowestPrice: Number(this.form.lowestPrice), //最低価格
              originalPrice: Number(this.form.originalPrice), //元の価格
              productTitle: this.form.productTitle, //商品タイトル
              recommandType: this.form.recommandType, //おすすめタイプ
              merchantId: this.form.merchantId, // 販売者ID
              categoryId: this.form.categoryId, //商品カテゴリーID
              stockAmount: Number(this.form.stockAmount), //在庫数
              soldAmount: Number(this.form.soldAmount), //販売済み数量
              weight: Number(this.form.weight), // 重み（優先度）
              hits: Number(this.form.hits), // クリック数（閲覧数）
              isSale: this.form.isSale, //セール中かどうか
              productDesc: this.form.productDesc, //商品説明
              firstPicId: '',
              pictureIds: ''
              // firstPicId: this.firstPicId.toString(),
              // pictureIds: this.pictureIds.toString()
            }
            this.productService.add(data).then(res => {
              if (res.data.success == 'true') {
                this.$message.success('保存成功')
                this.goBack()
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        })
      } else if (this.type == 'update') {
        this.UpdateStaff(formName)
      }
    },
    //商品管理編集
    async UpdateStaff(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          var eirong = this.$refs['eidtLists'].passFather()
          // console.log(eirong)
          var pictureIds = ''
          // 如果eirong是数组
          if (Array.isArray(eirong)) {
            eirong.forEach(item => {
              pictureIds += item.url + ',';
            })
          }

          var data = {
            productId: this.productId,
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            productName1: this.form.productName1, //商品名1
            productName2: this.form.productName2, //商品名2
            lowestPrice: Number(this.form.lowestPrice), //最低価格
            originalPrice: Number(this.form.originalPrice), // 元の価格
            productTitle: this.form.productTitle, //商品タイトル
            recommandType: this.form.recommandType, //おすすめタイプ
            merchantId: this.form.merchantId, // 販売者ID
            categoryId: this.form.categoryId, // 商品カテゴリーID
            stockAmount: Number(this.form.stockAmount), //在庫数
            soldAmount: Number(this.form.soldAmount), //販売済み数量
            weight: Number(this.form.weight), // 重み（優先度）
            hits: Number(this.form.hits), // クリック数（閲覧数）
            isSale: this.form.isSale, //セール中かどうか
            productDesc: this.form.productDesc, //商品説明
            firstPicId: this.form.firstPicId,
            pictureIds,
            productId: this.form.productId
            // firstPicId: this.firstPicId.toString(),
            // pictureIds: this.pictureIds.toString()
          }

          this.productService.update(data).then(res => {
            if (res.data.success == 'true') {
              this.$message.success('更新に成功しました')
              this.goBack()
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          return false
        }
      })
    },
    //前のページに戻る
    goBack() {
      // localStorage.setItem('updateState', true)
      this.$router.go(-1)
    },

    // スライド
    success(response) {
      this.form.firstPic = response.id
      if (this.form.firstPic.length > 0) {
        this.hideUpload = true
      }
    },
    handlePicRemove(file, fileList) {
      if (this.form.firstPic.length == 0) {
        this.hideUpload = false
      }
    },
    handleExceedTT() {
      this.$message.error('アップロードできる画像は1枚までです！')
    },

    // 商人の選択結果を取得
    submitForm() {
      var item = this.$refs['eidtListM'].passFather()
      if (item == null) {
        item = []
      }
      if (item.length != 0) {
        // 子コンポーネントで入力された内容を取得
        this.form.merchantName = item.merchantName // 商人名
        this.form.merchantId = item.merchantId //商人ID
      }
      this.merchantDialog = false
    },
    //商品カテゴリを取得
    async GetMerchantCategoryList() {
      this.loading = false
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        currentPage: 1, //ページ数
        pageSize: 50 //1ページあたりの件数
      }
      this.categoryService.list(date).then(res => {
        if (res.data.success == 'true') {
          this.loading = false
          res.data.result.forEach(item => {
            this.categorylist.push({
              label: item.categoryName,
              value: item.categoryId
            })
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-button1 {
  margin-top: 2px;
}

.el-card {
  margin-top: 15px;
}

textarea {
  height: 100px;
}

.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

/deep/ .el-table__row .el-upload--picture-card {
  height: 40px !important;
}

/deep/.el-table__row .el-upload-list--picture-card .el-upload-list__item {
  height: 40px;
  margin-top: 15px;
  border: none !important;
  background-color: transparent !important;
}

/deep/.el-table__row .el-upload-list--picture-card .el-upload-list__item img {
  width: 40px;
}

/deep/.el-table__row .el-upload-list__item.is-success .el-upload-list__item-status-label {
  display: none;
}

/deep/.el-table__row .el-upload--picture-card {
  line-height: 46px;
}

.avatar-uploader-icon {
  border: 1px dashed #d9d9d9 !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px !important;
  position: relative !important;
  overflow: hidden !important;
}

.avatar-uploader .el-upload:hover {
  border: 1px dashed #d9d9d9 !important;
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 300px;
  height: 178px;
  display: block;
}
</style>
