import { render, staticRenderFns } from "./GetMerchantsList.vue?vue&type=template&id=64feb792&scoped=true"
import script from "./GetMerchantsList.vue?vue&type=script&lang=js"
export * from "./GetMerchantsList.vue?vue&type=script&lang=js"
import style0 from "./GetMerchantsList.vue?vue&type=style&index=0&id=64feb792&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64feb792",
  null
  
)

export default component.exports