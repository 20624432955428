import request from "@/utils/service.js"
export default class productService {
  
  list(data) {
    return request({
      url: 'm_getProductList',
      method: 'post',
      data: data
    })
  }
  detail(data) {
    return request({
      url: 'm_getProduct',
      method: 'post',
      data: data
    })
  }
  add(data) {
    return request({
      url: 'm_addProduct',
      method: 'post',
      data: data
    })
  }
  update(data) {
    return request({
      url: 'm_updateProduct',
      method: 'post',
      data: data
    })
  }
  delete(data) {
    return request({
      url: 'm_deleteProduct',
      method: 'post',
      data: data
    })
  }
}
