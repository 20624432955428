<template>
    <div>
        <el-upload :disabled="flags" style="height: 0px" class="avatar-uploader" :action="UploadPics + '/10000/10000'"
            name="image" :headers="MyHeader" multiple :show-file-list="false" :on-success="uploadSuccess"
            :on-error="uploadError" :before-upload="beforeUpload">
        </el-upload>
        <quill-editor style="width: 75.5%; display: inline-block" class="editor" :class="[flags ? 'editornone' : '']"
            :disabled="flags" v-model="form.articleContent" :options="editorOption" ref="myQuillEditor"
            @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)"></quill-editor>
    </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
// 工具栏配置
const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // 太字、斜体、下線、取り消し線
    ["blockquote", "code-block"], // 引用、コードブロック
    // [{ header: 1 }, { header: 2 }], // 1、2レベルの見出し
    [{ list: "ordered" }, { list: "bullet" }], // 番号付きリスト、箇条書き
    [{ script: "sub" }, { script: "super" }], // 下付き文字、上付き文字
    [{ indent: "-1" }, { indent: "+1" }], // インデント
    [
        {
            color: [
                "#ffffff",
                "#ffd7d5",
                "#ffdaa9",
                "#fffed5",
                "#d4fa00",
                "#73fcd6",
                "#a5c8ff",
                "#ffacd5",
                "#ff7faa",
                "#d6d6d6",
                "#ffacaa",
                "#ffb995",
                "#fffb00",
                "#73fa79",
                "#00fcff",
                "#78acfe",
                "#d84fa9",
                "#ff4f79",
                "#b2b2b2",
                "#d7aba9",
                "#ff6827",
                "#ffda51",
                "#00d100",
                "#00d5ff",
                "#0080ff",
                "#ac39ff",
                "#ff2941",
                "#888888",
                "#7a4442",
                "#ff4c00",
                "#ffa900",
                "#3da742",
                "#3daad6",
                "#0052ff",
                "#7a4fd6",
                "#d92142",
                "#000000",
                "#7b0c00",
                "#ff0000",
                "#d6a841",
                "#407600",
                "#007aaa",
                "#021eaa",
                "#797baa",
                "#ab1942"
            ]
        },
        {
            background: [
                "#ffffff",
                "#ffd7d5",
                "#ffdaa9",
                "#fffed5",
                "#d4fa00",
                "#73fcd6",
                "#a5c8ff",
                "#ffacd5",
                "#ff7faa",
                "#d6d6d6",
                "#ffacaa",
                "#ffb995",
                "#fffb00",
                "#73fa79",
                "#00fcff",
                "#78acfe",
                "#d84fa9",
                "#ff4f79",
                "#b2b2b2",
                "#d7aba9",
                "#ff6827",
                "#ffda51",
                "#00d100",
                "#00d5ff",
                "#0080ff",
                "#ac39ff",
                "#ff2941",
                "#888888",
                "#7a4442",
                "#ff4c00",
                "#ffa900",
                "#3da742",
                "#3daad6",
                "#0052ff",
                "#7a4fd6",
                "#d92142",
                "#000000",
                "#7b0c00",
                "#ff0000",
                "#d6a841",
                "#407600",
                "#007aaa",
                "#021eaa",
                "#797baa",
                "#ab1942"
            ]
        }
    ], // フォントの色、背景色
    [{ size: ["small", false, "large", "huge"] }], // フォントサイズ
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 見出し
    [{ font: [] }], // フォントの種類
    [{ direction: "rtl" }], // テキストの方向
    [{ align: [] }], // 整列方法
    ["clean"], // テキストの書式をクリア
    ["link", "image", "video"] // リンク、画像、動画
]

export default {
    inject: ["reload"],
    props: {
        fuwenbenneirong: {
            type: String,
            default: ''
        },
        flags: {
            type: Boolean,
            default: true
        }
    },
    components: {
        quillEditor
    },
    watch: {
        value(val) {
            this.content = this.value
        }
    },
    data() {
        return {
            diseaseIdsid: "",
            num: this.$route.query.num,
            articleId: this.$route.query.articleId,
            name: "",
            mm: "",
            options: [],
            logo: true,
            flag: false,
            picture: [],
            dialogVisible: false,
            dialogImageUrl: "",
            isMax: false,
            petBreedDetailList: [],
            MyHeader: { accessToken: "", fp: "" },
            loading: true,
            diseaseIds: [],
            id: "",
            form: {
                articleTitle: "",
                articleContent: "",
                firstPic: "",
                weight: "",
                enabled: ""
            },
            editorOption: {
                theme: "snow", // or 'bubble'
                placeholder: "何か書いてみませんか？",
                modules: {
                    toolbar: {
                        container: toolbarOptions,
                        handlers: {
                            image: function (value) {
                                if (value) {
                                    // 触发input框选择图片文件
                                    document
                                        .querySelector(".avatar-uploader input")
                                        .click()
                                } else {
                                    this.quill.format("image", false)
                                }
                            }
                        }
                    }
                }
            },
        }
    },
    created() {
        this.UploadPics = this.$url.UploadPicture
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        this.MyHeader.currentRole = localStorage.getItem("role")
    },
    mounted() {
        this.wkve()
    },
    methods: {
        wkve() {
            this.form.articleContent = this.fuwenbenneirong
        },
        passFather() {
            return this.form.articleContent;   //this.ruleForm是子组件填写的数据
        },
        onEditorBlur() {
            //失去焦点事件
        },
        onEditorFocus(event) {
            //获得焦点事件
        },
        onEditorChange({ editor, html, text }) {
            this.content = html
            this.$emit("textareaData", this.content)
        },
        uploadSuccess(res, file) {
            let quill = this.$refs.myQuillEditor.quill
            let length = quill.getSelection().index
            quill.insertEmbed(
                length,
                "image",
                this.$url.urlcq + res.id,
                {
                    width: "1000px",
                    // height: "100%"
                }
            )
            quill.setSelection(length + 1)
        },
        // 富文本图片上传前
        beforeUpload() { },
        // 富文本图片アップロード失敗
        uploadError() {
            this.$message.error("图片插入失败")
        },
        success(response) {
            this.form.firstPic = response.id
        },
        change: function (file, fileList) {
            if (fileList.length >= 1) {
                this.isMax = true
            } else if (fileList.length < 1) {
                this.isMax = false
            }
        },
        error: function (err, file, fileList) {
            this.$message.error("アップロード失敗")
            if (fileList.length >= 1) {
                this.isMax = true
            } else if ((fileList.length = 0)) {
                this.isMax = false
            }
        },
    }
}
</script>

<style scoped>
.disabled .el-upload--picture-card {
    display: none;
}

.el-button1 {
    margin-top: 2px;
}

.el-card {
    margin-top: 15px;
}

.el-textarea__inner {
    border: 1px solid #cccccc;
    color: black;
    font-weight: bold;
}
</style>
<style lang="less">
.ql-snow .ql-picker {
    color: #444;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 38px;
    position: relative;
    vertical-align: middle;
}

.quill-editor {
    .ql-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .ql-container {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.editornone .ql-toolbar {
    display: none;
}

.editornone {
    .ql-container {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid #ccc !important;
    }
}
</style>