<template>
    <div id="wenjianshangchaun">
        <div>
            <el-form>
                <!-- <el-form-item label="商品の詳細写真"> -->
                <div style="display: flex;">
                    <!-- 使用element-ui自带样式 -->
                    <ul class="el-upload-list el-upload-list--picture-card">
                        <draggable v-model="updatalist">
                            <li v-for="(item, index) in updatalist" :key="index"
                                class="el-upload-list__item is-success animated">
                                <img :src="$url.urlcqs + item.url" alt="" class="el-upload-list__item-thumbnail upimg">
                                <i class="el-icon-close"></i>
                                <span class="el-upload-list__item-actions">
                                    <!-- プレビュー -->
                                    <span class="el-upload-list__item-preview"
                                        @click="handlePictureCardPreviewFileDetail(item, index)">
                                        <i class="el-icon-zoom-in"></i>
                                    </span>
                                    <!-- 削除 -->
                                    <span class="el-upload-list__item-delete" @click="handleRemoveFileDetail(index)">
                                        <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </li>
                        </draggable>
                    </ul>
                    <el-upload 
                        multiple 
                        :show-file-list="false" 
                        :file-list="addfileList"
                        action="#" 
                        list-type="picture-card" 
                        :on-success="success"
                        :http-request="uploadImg"
                        accept=".jpg,.png,.jpeg,.tif,.bmp,.gif"
                        :headers="MyHeader" :on-preview="handlePictureCardPreviewFileDetail"
                        :before-remove="handleRemoveFileDetail" :on-change="handleChangeFileDetail">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <!-- 预览弹出层 -->
                <el-dialog :visible.sync="dialogVisibleDetail">
                    <div class="demo-image__preview lookimg">
                        <img height="400px" :src="dialogImageDetailUrl" id="upimage">
                    </div>
                </el-dialog>
                <!-- </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>

<script>
// draggableコンポーネントをインポート
import draggable from "vuedraggable"
import axios from 'axios'

export default {
    // draggableコンポーネントを登録
    components: {
        draggable
    },
    name: "wenjianshangchaun",
    props: {
        pictureLB: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            dialogVisible: false,
            accept: "",
            addfileList: [],
            updatalist: [],
            dialogVisibleDetail: false,
            dialogImageDetailUrl: "",
            deg: [],
            UploadPics: '',
            MyHeader: {
                accessToken: "",
                fp: ""
            },
        }
    },
    created() {
        this.UploadPics = this.$url.UploadPicture
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        this.MyHeader.fp = localStorage.getItem("fp")
        this.updatalist = this.pictureLB
    },

    methods: {
    async formDataToBase64(formData) {
      let base64Data = {};
      
      for (let [key, value] of formData.entries()) {
          if (value instanceof File) {
              const fileReader = new FileReader();
              const promise = new Promise((resolve, reject) => {
                  fileReader.onload = function(e) {
                      resolve(e.target.result.split(',')[1]); // 移除"data:image/*;"部分，仅保留Base64数据
                  };
                  fileReader.onerror = reject;
              });
              
              fileReader.readAsDataURL(value);
              base64Data[key] = await promise;
          }
      }
      
      
      return base64Data;
    },
    /**スライド */
    
    uploadImg(item) {
      this.loading = true
      //上传文件的需要formdata类型;所以要转

      // 判断文件是否大于10M
      if (item.file.size > 10 * 1024 * 1024) {
        this.$message.error('アップロードするファイルのサイズは10MBを超えてはいけません！')
        this.pictureTT = []
        return false
      }

      let originalFileExt = item.file.name.split('.')[1]

      // 如果originalFileExt在[jpg,png,jpeg,tif,bmp,gif]存在
      if (['jpg', 'png', 'jpeg', 'tif', 'bmp', 'gif'].indexOf(originalFileExt) == -1) {
        this.$message.error('アップロードファイルの拡張子が正しくない!')
        this.pictureTT = []
        return false
      }
      

      let FormDatas = new FormData()
      FormDatas.append('file',item.file);
      let base64Img = '';

      this.formDataToBase64(FormDatas).then(base64Data => {
        base64Img = base64Data;

        let accessToken = localStorage.getItem('authToken');
        let currentRole = localStorage.getItem('currentRole');
        
        let params = {
          accessToken: accessToken,
          currentRole,
          file: base64Img.file,
          originalFileName: item.file.name,
          originalFileExt
        }
        // FormDatas.append('user_token', this.user_token);
        // console.log(this.UploadPics)
        axios({
          url: this.UploadPics,
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          timeout: 30000,
          data: params
        }).then( res => {
          let fileImg = {
            url: res.data.fileId
          }
          this.updatalist.push(fileImg)
        })
        // this.$http({
        //   method: 'post',
        //   url: this.UploadPics,
        //   headers: { 'Content-Type': 'multipart/form-data' },
        //   timeout: 30000,
        //   data: FormDatas
        //   }).then(res=>{

        //   })
      }).catch(error => {
        console.error('Error converting to Base64:', error);
      });

      
    },
        success(response, file, fileList) {
            if (response.msgCode == "1") {
                this.$message.success("画像のアップロードに成功しました")
            } else if (response.msgCode !== "1") {
                this.$message({
                    type: "error",
                    message: response.message
                })
            }
        },
        handleChangeFileDetail(file, fileList) {
            if (file.response) {
                var obj = {
                    deg: 0,
                    status: file.status,
                    uid: file.uid,
                    url: this.$url.urlcq + file.response.id,
                }
                this.updatalist.push(obj)
            }
        },
        passFather() {
            return this.updatalist
        },
        passFatherFile() {
            return this.addfileList
        },
        // 放大
        handlePictureCardPreviewFileDetail(file, index) {
            this.dialogImageDetailUrl = file.url
            this.dialogVisibleDetail = true
        },
        // 削除
        handleRemoveFileDetail(index) {
            this.updatalist.splice(index, 1)
        },
    }
}
</script>
<style scoped lang="less">
.lookimg {
    text-align: center;
    width: 100%;
    height: 400px;
    line-height: 400px;

}
</style>
